<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <div class="vx-row no-gutter justify-center items-center">
  
              <div class="vx-col hidden lg:block">
                <center><img src="@/assets/logo.png" alt="login" class="mx-auto lg:w-1/4"></center>
              </div>
  
              <div class="vx-col mx-auto lg:w-1/2">
                <div class="p-8 login-tabs-container">
  
                  <div class="mb-4">
                    <h1 class="text-4xl">LOG <i>in</i><br/><br/></h1>
                  </div>
  
                  <div>

                    <md-field>
                      <label>Email</label>
                      <md-input v-model="email"></md-input>
                    </md-field>

                    <md-field>
                      <label>Password</label>
                      <md-input v-model="password" type="password"></md-input>
                    </md-field>
  
                    <div class="flex flex-wrap justify-between my-5">
                        <router-link to="/forgot" class="oeno-link">Forgot Password?</router-link>
                    </div>
                    <md-button @click="loginUser()" class="float-right oeno-button" :disabled="loggingin">{{loggingin?'Logging In':'Login'}}</md-button>
  
                  </div>
  
                </div>
              </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import {HTTP} from "@/axios.js"
  
  export default{
    data() {
      return {
        email: "",
        password: "",
        loggingin: false,
      }
    },
    methods: {
      loginUser() {
  
        // let login now
  
        this.loggingin = true;
  
        HTTP
          .post("/api/phone-login", {email: this.email, password: this.password}  )
          .then(response => {
  
            this.loggingin = false;


            if (response.data.statuscode == 200) {

              this.$store.dispatch('login', {token: response.data.data.token}) 

              // Now get the users profile
              const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + response.data.data.token
              }
      
              HTTP.get('/api/get-profile' , { headers: headers}).then(
                  (response) => {             

                    this.$store.dispatch('storeUserData', {userdata: response.data.data.user}) 

                    this.$router.push('/portfolio')

                  })



            } else {

              this.$notify({
                group: 'oeno',
                title: 'Login Error',
                text: 'Sorry there seems to have been an error processing your Login, please try again later.',
                type: 'error',
              });

            }
  
  
          })
          .catch(error => {

            this.loggingin = false;

            this.$notify({
              group: 'oeno',
              title: 'Login Error',
              text: 'Sorry there seems to have been an error processing your Login, please try again later.',
              type: 'error',
            });
  
            console.log(error);

          });
  
      }
    }
  }
  </script>
  
  <style lang="scss">
  #page-login {
    .social-login-buttons {
      .bg-facebook { background-color: #1551b1 }
      .bg-twitter { background-color: #00aaff }
      .bg-google { background-color: #4285F4 }
      .bg-github { background-color: #333 }
    }
  }
  </style>
  